import type {
  ComponentProps,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  Ref,
} from 'react';
import { Link, usePathname } from '@/navigation';
import { isClient } from '~/const/env';

interface ActiveLinkProps extends ComponentProps<typeof Link> {
  className?: string;
  children: ReactNode;
  id?: string;
  onClick?: MouseEventHandler;
  onKeyDown?: (e: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  ref?: Ref<any>;
  rel?: string;
  tabIndex?: number;
  title?: string;
}

export const ActiveLink = ({
  href,
  children,
  className,
  ...props
}: ActiveLinkProps) => {
  const pathname = usePathname();

  const linkClasses = [];
  const url = isClient
    ? new URL(href as string, window.location.href).pathname
    : (href as string);

  const isActive = [url, `${url}/[id]`].includes(pathname);

  isActive && linkClasses.push('active');
  !!className?.length && linkClasses.push(className);

  return (
    <Link
      {...(linkClasses.length && {
        className: linkClasses.join(' '),
      })}
      href={href}
      prefetch={false}
      {...props}
    >
      {children}
    </Link>
  );
};
