import type { MessageKeys } from 'next-intl';
import type { Messages } from '@/global';
import appConst from '~/const/appConst';

export const dataLinks = [
  {
    id: 'my_profile',
    route: appConst.staticRoutes.profile,
    label: 'GLOBAL.MY_PROFILE' as MessageKeys<Messages, 'GLOBAL.MY_PROFILE'>,
    cy: 'profile',
  },
  {
    id: 'my_participations',
    route: appConst.staticRoutes.my_participations,
    label: 'NAVIGATION.MY_PARTICIPATIONS' as MessageKeys<Messages, 'NAVIGATION.MY_PARTICIPATIONS'>,
    cy: 'participations',
  },
  {
    id: 'settings',
    route: appConst.staticRoutes.settings,
    label: 'GLOBAL.SETTINGS' as MessageKeys<Messages, 'GLOBAL.SETTINGS'>,
    cy: 'settings',
  },
];
