import type {
  Dispatch,
  KeyboardEvent,
} from 'react';
import { useContext, useEffect, useState } from 'react';
import type { AxiosError } from 'axios';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { VpButton, VpIcon } from '@vtmn-play/react/headless';
import { dataLinks } from './data';
import { Link, usePathname } from '@/navigation';
import { throwErrorsAction } from '~/actions/actionsErrors';
import { Avatar } from '~/components/commons/Avatar/Avatar';
import type { UseDispatchType } from '~/model/GlobalTypes';
import { handleLogout, initError } from '~/utils/utils';
import { handleArrowKeyDown, useFlyoutMenu } from '~/hooks/useFlyoutMenu';
import appConst from '~/const/appConst';
import { userSelector } from '~/selectors/selectors';
import { isAuthenticatedAsMainUser, isMainUser } from '~/utils/user/user';
import { ScrollContext } from '~/components/commons/Layout/Layout';
import { ActiveLink } from '~/components/commons/ActiveLink/ActiveLink';
import { GenericDialog } from '~/components/commons/genericDialog/GenericDialog';
import { useBreakpoint } from '~/hooks/match-media/useBreakpoint';
import { CustomSwipeableDrawer } from '~/components/commons/CustomSwipeableDrawer/CustomSwipeableDrawer';

export const AvatarMenu = ({
  setIsLoading,
  authenticated,
  handleRedirectToLogin,
}: AvatarMenuProps) => {
  const t = useTranslations();
  const dispatch = useDispatch<UseDispatchType>();
  const pathname = usePathname();

  const user = useSelector(userSelector);

  const { isScrollingUp } = useContext(ScrollContext);

  const { isSBreakpoint } = useBreakpoint();

  const [openDialog, setOpenDialog] = useState(false);

  const throwErrorsDispatcher = (res: AxiosError, message?: string) =>
    dispatch(throwErrorsAction(initError({ res, message })));

  // Flyout Menu custom hook
  const {
    flyoutWrapperRef,
    toggleFlyout,
    focusables,
    addToRefs,
    isOpened,
    setIsOpened,
  } = useFlyoutMenu();

  const handleKeyDown = (
    e: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    handleArrowKeyDown({ focusables, isOpened, setIsOpened })(e);
  };

  useEffect(() => {
    isOpened && setIsOpened(isScrollingUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollingUp]);

  return (
    <>
      {isAuthenticatedAsMainUser(user, authenticated) && (
        <ActiveLink
          className="header__messages vp-icon-button vp-icon-button--tertiary"
          href={appConst.staticRoutes.messages}
          title={t('NAVIGATION.MESSAGES')}
          prefetch={false}
        >
          <VpIcon name="message" />
        </ActiveLink>
      )}
      <div
        className="header__user-identifier"
        data-cy="avatar"
        ref={flyoutWrapperRef}
      >
        <button
          type="button"
          ref={addToRefs}
          onKeyDown={handleKeyDown}
          className={classNames('header__avatar', {
            'header__avatar--opened': isOpened,
          })}
          onClick={
            isAuthenticatedAsMainUser(user, authenticated)
              ? toggleFlyout
              : handleRedirectToLogin
          }
          {...(
            authenticated
            && isMainUser(user) && {
              title: t('GLOBAL.MY_PROFILE'),
              'aria-expanded': isOpened,
              'aria-controls': 'profile-menu',
              'aria-labelledby': 'navigation-my_profile',
            })}
        >
          <Avatar
            user={user}
            authenticated={authenticated}
            avatarSize={40}
            ariaHidden
            fromHeader
          />
        </button>
        {isOpened && (
          <div
            id="profile-menu"
            className="header__flyout"
            data-testid="flyout"
          >
            <ul className="header__flyout__inner">
              {dataLinks.map(link => (
                <li key={link.id}>
                  <Link
                    id={`navigation-${link.id}`}
                    className={classNames(
                      'header__button button-wrapper-link',
                      {
                        active: pathname === link.route,
                      },
                    )}
                    data-cy={link.cy}
                    href={link.route}
                    onClick={toggleFlyout}
                    onKeyDown={handleKeyDown}
                    prefetch={false}
                    ref={addToRefs}
                    rel="noindex, nofollow"
                    tabIndex={-1}
                  >
                    {t(link.label)}
                  </Link>
                </li>
              ))}
              <li>
                <button
                  type="button"
                  ref={addToRefs}
                  tabIndex={-1}
                  onKeyDown={handleKeyDown}
                  className="header__button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpened(false);
                    setOpenDialog(true);
                  }}
                >
                  <VpIcon name="power" size={16} />
                  {' '}
                  {t('GLOBAL.LOGOUT_2')}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      {isSBreakpoint
        ? (
          <CustomSwipeableDrawer
            dataTestId="logout"
            open={openDialog}
            title={t('GLOBAL.LOGOUT_2')}
            onOpen={() => setOpenDialog(true)}
            onClose={() => setOpenDialog(false)}
          >
            <p>{t('GLOBAL.LOGOUT_CONFIRM')}</p>
            <div className="logout__actions">
              <VpButton
                data-testid="secondary-button"
                variant="secondary"
                size="medium"
                shape="squared"
                onClick={() => setOpenDialog(false)}
              >
                {t('PROFILE.GLOBAL.CANCEL')}
              </VpButton>
              <VpButton
                data-cy="confirm-choice"
                data-testid="confirm-choice"
                size="medium"
                shape="squared"
                onClick={() =>
                  handleLogout(setIsLoading, throwErrorsDispatcher, t)}
              >
                {t('GLOBAL.LOGOUT')}
              </VpButton>
            </div>
          </CustomSwipeableDrawer>
          )
        : (
          <GenericDialog
            isOpen={openDialog}
            isClosable={false}
            data-testid="GenericDialog"
            primaryButtonTitle={t('GLOBAL.LOGOUT')}
            primaryButtonClick={() =>
              handleLogout(setIsLoading, throwErrorsDispatcher, t)}
            secondaryButtonTitle={t('PROFILE.GLOBAL.CANCEL')}
            secondaryButtonClick={() => setOpenDialog(false)}
            content={<p>{t('GLOBAL.LOGOUT_CONFIRM')}</p>}
            title={t('GLOBAL.LOGOUT_2')}
          />
          )}
    </>
  );
};

export interface AvatarMenuProps {
  setIsLoading: Dispatch<boolean>;
  authenticated: boolean;
  handleRedirectToLogin: () => void;
}
