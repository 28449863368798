import type {
  Dispatch,
  KeyboardEvent,
  MutableRefObject,
} from 'react';
import {
  useEffect,
  useRef,
} from 'react';
import { useCloseOnQuit } from '~/hooks/useCloseOnQuit';
import { keyboardKeys } from '~/const/keyCodes';

export const handleArrowKeyDown
  = ({
    focusables,
    isOpened,
    setIsOpened,
  }: {
    focusables: MutableRefObject<any[]>;
    isOpened: boolean;
    setIsOpened: Dispatch<boolean>;
  }) =>
    (e: KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      const key = e.code;
      const target = e.target;
      const elements = focusables.current;
      const index = elements.indexOf(target);

      // Open the menu if it is still closed
      // and when pressed key is ArrowUp of ArrowDown
      if ((!isOpened && key === keyboardKeys.DOWN) || key === keyboardKeys.UP)
        setIsOpened(true);

      // Tab key should close the menu
      if (key === keyboardKeys.TAB)
        setIsOpened(false);

      // ArrowDown key should focus next element of the menu
      // and loop back to the first focusable element
      if (key === keyboardKeys.DOWN) {
        e.preventDefault();
        const nextIndex = elements.length - 1 === index ? 0 : index + 1;
        elements[nextIndex].focus();
      }
      // ArrowUp key should focus previous element of the menu
      // and loop back to the last focusable element
      if (key === keyboardKeys.UP) {
        e.preventDefault();
        const prevIndex = index === 0 ? elements.length - 1 : index - 1;
        elements[prevIndex].focus();
      }
    };

export const useFlyoutMenu = () => {
  const focusables = useRef([]);
  const { ref: flyoutWrapperRef, isOpened, setIsOpened } = useCloseOnQuit();

  const toggleFlyout = () => {
    setIsOpened(value => !value);
  };

  const addToRefs = (el) => {
    if (el && !focusables.current.includes(el))
      focusables.current.push(el);
  };

  useEffect(() => {
    if (!isOpened && focusables.current.length > 1)
      focusables.current = focusables.current.slice(0, 1);
  }, [isOpened]);

  return {
    flyoutWrapperRef,
    focusables,
    addToRefs,
    handleArrowKeyDown,
    isOpened,
    setIsOpened,
    toggleFlyout,
  };
};
