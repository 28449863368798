'use client';

import classNames from 'classnames';
import { VpIcon } from '@vtmn-play/react/headless';
import { useTranslations } from 'next-intl';
import { ResizedImg } from '../ResizedImg/ResizedImg';
import type { UserType } from '~/model/UserType';
import { isMainUser } from '~/utils/user/user';

export interface AvatarProps {
  user: UserType;
  authenticated?: boolean;
  avatarSize?: number;
  ariaHidden?: boolean;
  fromHeader?: boolean;
}

const AvatarVpColor = {
  '#FFE4D6': 'var(--vp-semantic-color-content-on-media-accent)',
  '#BEDEEF': 'var(--vp-semantic-color-content-on-media-accent)',
  '#FFFBC7': 'var(--vp-semantic-color-container-commercial-catchy)',
  '#DCCFDD': 'var(--vp-semantic-color-container-commercial-catchy)',
  '#CBF0C7': 'var(--vp-semantic-color-container-commercial-neutral',
  media: 'var(--vp-semantic-color-background-main)',
};

export const Avatar = ({
  user,
  authenticated,
  avatarSize = 40,
  ariaHidden = false,
  fromHeader = false,
}: AvatarProps) => {
  const t = useTranslations();

  const {
    media = null,
    firstname = null,
    lastname = null,
    avatar_color: color = null,
  } = user || {};

  const defaultAvatarColor = 'var(--vp-semantic-color-container-brand)';
  const avatarColor
    = AvatarVpColor[media ? 'media' : color] || defaultAvatarColor;

  const isAvatarColorDefault = avatarColor === defaultAvatarColor;

  const style = {
    '--avatar-color': avatarColor,
    width: avatarSize,
    height: avatarSize,
  };

  const avatarAttributes = ariaHidden
    ? {
        'aria-hidden': true,
      }
    : { title: `${firstname || ''} ${lastname || ''}` };

  const imgAltAttribute = !ariaHidden && !!firstname ? firstname : '';

  return (
    <div
      className={classNames('user-identifier', {
        'user-identifier--without-name': !fromHeader,
      })}
    >
      {isMainUser(user) && (
        <div
          data-testid="avatar"
          className="avatar"
          {...avatarAttributes}
          style={style}
        >
          <span className="avatar__background" />
          {media
            ? (
              <ResizedImg
                alt={imgAltAttribute}
                aria-hidden={ariaHidden}
                data-testid="user-image"
                format="140x140"
                fill
                sizes="140px"
                priority
                style={{ objectFit: 'cover' }}
                src={media.content_url}
              />
              )
            : (
              <span
                className={classNames('avatar__default vp-title-s', {
                  'avatar__default--reversed': isAvatarColorDefault,
                })}
              >
                {firstname
                  ? (
                      firstname[0]?.toUpperCase()
                    )
                  : (
                    <VpIcon name="user" />
                    )}
              </span>
              )}
        </div>
      )}
      {fromHeader && (!authenticated || !isMainUser(user)) && (
        <span data-testid="btn-connect" className="vp-button vp-button--small">
          <div className="vp-button__label" data-part="label">
            {t('GLOBAL.LOGIN_2')}
          </div>
        </span>
      )}
    </div>
  );
};
