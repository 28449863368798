'use client';

import { SwipeableDrawer } from '@mui/material';
import { VpIcon, VpIconButton } from '@vtmn-play/react/headless';
import type { ReactNode } from 'react';

export const CustomSwipeableDrawer = ({
  children,
  closable = true,
  dataTestId,
  open,
  title,
  onClose,
  onOpen,
}: {
  children: ReactNode;
  closable?: boolean;
  dataTestId: string;
  open: boolean;
  title: string;
  onClose: () => void;
  onOpen?: () => void;
}) => {
  return (
    <SwipeableDrawer
      className="custom-swipeable-drawer"
      data-testid={dataTestId}
      anchor="bottom"
      open={open}
      {...(closable && { onClose })}
      onOpen={onOpen}
    >
      <div className="custom-swipeable-drawer__container">
        <span className="custom-swipeable-drawer__swipe-indicator" />
        <div className="custom-swipeable-drawer__header">
          <span className="vp-title-s">{title}</span>
          {closable && (
            <VpIconButton onClick={onClose} variant="tertiary">
              <VpIcon name="close" size={32} />
            </VpIconButton>
          )}
        </div>
        {children}
      </div>
    </SwipeableDrawer>
  );
};
