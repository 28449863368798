import { useEffect, useRef, useState } from 'react';
import { keyboardKeys } from '~/const/keyCodes';

export const useCloseOnQuit = () => {
  const ref = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const handleMouseDown = (e) => {
    if (ref?.current && !ref.current.contains(e.target))
      setIsOpened(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === keyboardKeys.ESCAPE)
      setIsOpened(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('keydown', handleKeyDown);
    // return function to be called when unmount
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.addEventListener('keydown', handleKeyDown);
    };
  }, []);

  return { ref, isOpened, setIsOpened };
};
